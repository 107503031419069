



































































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { recruitmentPostController } from '../../dialogs/recruitment-post-controller'
import { RctDaoViewModel } from '../viewmodels/rct-dao-viewmodel'

@Observer
@Component({
  components: {},
})
export default class ProfileInfo extends Vue {
  @Inject({}) rctVm!: RctDaoViewModel

  recruitmentPostController = recruitmentPostController

  goToSettingPage() {
    this.$router.push(`/community-organize/dao-detail/${this.rctVm.communityId}/setting`)
  }

  selectCoverImageInput() {
    if (!this.rctVm.isAdmin) return
    const coverImageInput = document.getElementById('cover-image-select') as any
    coverImageInput.click()
  }
}
